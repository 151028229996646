<template>
    <div class="Confirm">
		<div class="container">
            <div class=" is-primary recipes">
			    <div class="notification is-primary contact">
                    <section>
                        <b-message title="Bestelling geplaatst!" type="is-success" aria-close-label="Close message">
                            Over enkele ogenblikken zal je een email ontvangen ter bevestiging! Bedankt voor uw bestelling.
                        </b-message>
                    </section>
			    </div>
			</div>
		</div>
    </div>
</template>

<script>
	//import axios from 'axios'

	export default {
		name: 'End',
		data () {
			return {
                
			}
		},
		methods: {
            

        },
        beforeMount() {
        
        }
	}
</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        /* display: inline-block; */
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
        color: black;
	}

	.green {
		color: #42b983;
	}
</style>
