<template>
    <div class="Login">
		<div class="container">
            <div class=" is-primary recipes">
			    <div class="notification is-primary contact form">
                    <section>
                        <h1 class="title is-1">Login</h1>
                        <b-field label="Username">
                            <b-input v-model="username" placeholder="Username" autocomplete="on"></b-input>
                        </b-field>
                        <b-field label="Password">
                            <b-input type="password"
                                placeholder="Password" v-model="password" autocomplete="on"
                                password-reveal>
                            </b-input>
                        </b-field>
                        <b-field label="Private key">
                            <b-input v-model="privateKey" placeholder="Private key" autocomplete="on"></b-input>
                        </b-field>

                        <div class="buttons">
                            <b-button type="is-primary" v-on:click="proceedLogin()" expanded>Login</b-button>
                        </div>
                    </section>
			    </div>
			</div>
		</div>
    </div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'Login',
		data () {
			return {
                username: "",
                password: "",
                privateKey: ""
                // errorMessage: ""
			}
		},
		methods: {
            proceedLogin() {
                axios.post('https://traiteurfeest.restaurantlolivier.be/api/private/login', {username: this.username, password: this.password, private_key: this.privateKey}) // https://traiteurlolivier.restaurantlolivier.be/api/
                    .then(response => {
                        if (response.data !== 401) {
                            localStorage.setItem('user-token', response.data)
                            this.$store.commit('login')
                            console.log(this.$store.state.loggedin)
                            this.$router.push({name: 'AllOrders'})
                        }
                    })
                    .catch(error => {
                        console.log(`${error}`)
                        this.$router.push({name: 'AllOrders'})
                        })

				//this.$router.push({name: 'End'})
            }
        },
        beforeMount() {
            
        }
	}
</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        /* display: inline-block; */
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
        color: black;
	}

	.green {
		color: #42b983;
	}

    .form {
        max-width: 400px;
    }
</style>
