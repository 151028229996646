<template>
    <div class="AddOrders">
		<div class="container">
            <div class=" is-primary recipes">
			    <div class="notification is-primary contact">
                    <section>
                        <h1 class="title is-1">Gerechten verwijderen:</h1>
                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Tapas
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in tapas" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i> </p><b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Soepen
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in soups" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>                     


                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Voorgerechten
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                        <li v-for="i in starters" :key="i.id">		
                                            <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                            <br>
                                            <br>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        
                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Specialiteiten
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in specialties" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>
    


                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Hoofdgerechten
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in main" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>
                        
                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Deserten
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <ul id="Tapas">
                                                <li v-for="i in deserts" :key="i.id">		
                                                    <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                    <br>
                                                    <br>
                                                </li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>
   
                        
                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
                                    <p class="card-header-title">
                                        Wijnen
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in wine" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>
                        <br>
                        <h3 class="title is-3">Gerechten verwijderen:</h3>

                        <!-- <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menustarter">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menustarter">
                                    <p class="card-header-title">
                                        Menu voorgerecht
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuStarter" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menuextra">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menuextra">
                                    <p class="card-header-title">
                                        Menu hapje
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuExtra" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menumain">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menumain">
                                    <p class="card-header-title">
                                        Menu hoofdgerecht
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuMain" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menudesert">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menudesert">
                                    <p class="card-header-title">
                                        Menu dessert
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuDesert" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="luxemain">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="lexumain">
                                    <p class="card-header-title">
                                        Luxe menu
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuLuxe" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="kidstarter">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidstarter">
                                    <p class="card-header-title">
                                        Kindermenu voorgerecht
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in kidMenuStarter" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="kidmain">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidmain">
                                    <p class="card-header-title">
                                        Kindermenu hoofdgerecht
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in kidMenuMain" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="kiddesert">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kiddesert">
                                    <p class="card-header-title">
                                        Kindermenu dessert
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in kidMenuDesert" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section> -->

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menustarter">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menustarter">
                                    <p class="card-header-title">
                                        Menu voorgerecht
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuLuxeStarter" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menustarter">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menustarter">
                                    <p class="card-header-title">
                                        Menu soep
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuLuxeSoep" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menumain">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menumain">
                                    <p class="card-header-title">
                                        Menu hoofdgerecht
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuLuxeMain" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>

                        <section>
                            <b-collapse :open="false" class="card" animation="slide" aria-id="menudesert">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menudesert">
                                    <p class="card-header-title">
                                        Menu Dessert
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <ul id="Tapas">
                                            <li v-for="i in menuLuxeDesert" :key="i.id">		
                                                <p>{{ i.name }} - <i>€{{ i.price }}</i></p> <b-button type="is-primary" v-on:click="deleteRecipe(i.id)">Verwijderen</b-button>
                                                <br>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </section>
                    </section>
			    </div>             
			    
			</div>
		</div>
    </div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'DeleteRecipes',
		data () {
			return {
                allDishes: null,
				tapas: [],
				soups: [],
				starters: [],
				specialties: [],
				main: [],
				deserts: [],
                wine: [],
                menuStarter: [],
                menuExtra: [],
                menuMain: [],
                menuDesert: [],
                menuLuxe: [],
                menuLuxeStarter: [],
				menuLuxeSoep: [],
				menuLuxeMain: [],
				menuLuxeDesert: [],
                kidMenuStarter: [],
                kidMenuMain: [],
                kidMenuDesert: [],
                recipeName: "",
                price: "",
                category: ""
			}
        },
        
		methods: {
            deleteRecipe(id) {
                axios.post('https://traiteurfeest.restaurantlolivier.be/api/private/delete_recipe', { // https://traiteurlolivier.restaurantlolivier.be/api/
                    token: localStorage.getItem("user-token"),
                    id: id, 
                    }).then(response => {
                        if (response.data == 200) {
                            this.$router.go()
                        }
                    })
                    .catch(error => console.log(`${error}`))
            },

            getItems() {
				axios.get('https://traiteurfeest.restaurantlolivier.be/api/get_menu') // https://traiteurlolivier.restaurantlolivier.be/api/
					.then(response => (this.allDishes = response.data))
					.catch(error => console.log(`Error at getItems ${error}`))
					.finally(() => this.sortDishes());
			},

            sortDishes() {
				this.allDishes.forEach(element => {
					if (element.type === 0) {
						this.tapas.push(element);
					} else if (element.type === 1) {
						this.soups.push(element);
					} else if (element.type === 2) {
						this.starters.push(element);
					} else if (element.type === 3) {
						this.specialties.push(element);
					} else if (element.type === 4) {
						this.main.push(element);
					} else if (element.type === 5) {
						this.deserts.push(element);
					} else if (element.type === 7) {
						this.wine.push(element);
					} else if (element.type === 12) {
						this.menuStarter.push(element);
					} else if (element.type === 13) {
						this.menuExtra.push(element);
					} else if (element.type === 14) {
						this.menuMain.push(element);
					} else if (element.type === 15) {
						this.menuDesert.push(element);
					} else if (element.type === 16) {
						this.menuLuxe.push(element);
					} else if (element.type === 200) {
						this.menuLuxeStarter.push(element);
					} else if (element.type === 201) {
						this.menuLuxeSoep.push(element);
					} else if (element.type === 202) {
						this.menuLuxeMain.push(element);
					} else if (element.type === 203) {
						this.menuLuxeDesert.push(element);
					} else if (element.type === 200) {
						this.kidMenuStarter.push(element);
					} else if (element.type === 202) {
						this.kidMenuMain.push(element);
					} else if (element.type === 200) {
						this.kidMenuDesert.push(element);
					} 
				});
            },
            
            refresh() {
                this.$router.push({name: 'DeleteRecipes'})
            }
        },

        beforeMount() {
            this.getItems();
        }
	}
</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        /* display: inline-block; */
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
        color: black;
	}

	.green {
		color: #42b983;
	}

    .form {
        max-width: 400px;
    }
</style>
