<template>
    <div class="Login">
		<div class="container">
            <div class=" is-primary recipes">
			    <div class="notification is-primary contact form">
                    <section>
                        <h1 class="title is-1">View orders:</h1>
                        
                        <b-field label="Afhaal dag*">
							<b-datepicker placeholder="Selecteer een datum" id="day" v-model="date"  @input="getAllOrders()"></b-datepicker>
						</b-field>
                        <hr>
                    </section>
			    </div>

                <div class="notification is-primary contact">
                    <section>
                        <div v-for="(order, index) in orders" :key="order.id">
                            <b-collapse :open="false" class="card" animation="slide" aria-id="order">
                                <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="order">
                                    <p class="card-header-title">
                                        Order van: {{ order.name }}
                                    </p>
                                    <a class="card-header-icon">
                                        <b-icon
                                            :icon="props.open ? 'menu-up' : 'menu-down'">
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="card-content">
                                    <div class="content">
                                        <p><b>Mail: </b> {{ order.email }}</p>
                                        <p><b>Nummer: </b> {{ order.phone }}</p>
                                        <p><b>Betaalmethode: </b> {{ order.payment }}</p>
                                        <p><b>Uur: </b> {{ order.hour }}</p>
                                        <p><b>Prijs: </b> {{ order.price }}</p>
                                        <p><b>Opmerking: </b> {{ order.comment }}</p>
                                        <!-- <p>{{orders_values[index]}}</p> -->

                                        <!-- <p> {{ orders_values[index][0].name }} </p> -->
                                        <div v-for="i in orders_values[index]" :key="i.id">
                                            <p> {{ i.name }} - <b>Aantal:</b> {{ i.amount }}</p> 
                                        </div>
                                        <br>
                                        <h5 v-if="menu[index].length" class="title is-5">Menu items:</h5>
                                        <div v-for="i in menu[index]" :key="i.id">
                                            <p> {{ i.name }} - <b>Aantal:</b> {{ i.amount }}</p> 
                                        </div>

                                        <br>
                                        <h5 v-if="luxe[index].length" class="title is-5">Luxe menu items:</h5>
                                        <div v-for="i in luxe[index]" :key="i.id">
                                            <p> {{ i.name }} - <b>Aantal:</b> {{ i.amount }}</p> 
                                        </div>

                                        <br>
                                        <h5 v-if="kidmenu[index].length" class="title is-5">Kindermenu items:</h5>
                                        <div v-for="i in kidmenu[index]" :key="i.id">
                                            <p> {{ i.name }} - <b>Aantal:</b> {{ i.amount }}</p> 
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </section>        
                </div>
                    
			    
			</div>
		</div>
    </div>
</template>

<script>
	import axios from 'axios'

	export default {
        name: 'Login',
        
		data () {
			return {
                date: new Date(),
                orders: null,
                orders_values: null,
                menu: [],
                luxe: [],
                kidmenu: []
			}
		},
		methods: {
            getAllOrders() {
                var realDate = this.date.getFullYear() + "-" + ("0" + (this.date.getMonth() + 1)).slice(-2) + "-" + ("0" + this.date.getDate()).slice(-2);
                axios.post('https://traiteurfeest.restaurantlolivier.be/api/private/get_orders', {token: localStorage.getItem("user-token"), date: realDate}) // https://traiteurlolivier.restaurantlolivier.be/api/private/get_orders
                    .then(response => {
                        if (response.data === 401 || response.data === 404) {
                            this.$router.push({name: 'Login'})
                        } else {
                            //console.log(JSON.stringify(response.data))
                            //console.log(JSON.stringify(response.data))
                            this.seperator(response.data)
                        }
                    })
                    .catch(error => console.log(`${error}`))
                    
                    
				//this.$router.push({name: 'End'})
            },

            seperator(data) {               
                this.orders = data[0];
                //console.log(JSON.parse(data[1]))
                this.orders_values = JSON.parse(data[1])
                this.menu = JSON.parse(data[2])
                this.luxe = JSON.parse(data[3])
                this.kidmenu = JSON.parse(data[4])
            }
        },
        beforeMount() {
            
        }
	}
</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        /* display: inline-block; */
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
        color: black;
	}

	.green {
		color: #42b983;
	}

    .form {
        max-width: 400px;
    }
</style>
