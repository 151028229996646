<template>
    <div class="AddOrders">
		<div class="container">
            <div class=" is-primary recipes">
			    <div class="notification is-primary contact form">
                    <section>
                        <h1 class="title is-1">Add Menu Recipes:</h1>
                        
                        <b-field label="Naam">
                            <b-input v-model="recipeName"></b-input>
                        </b-field>

                        <b-field label="Prijs">
                            <b-input v-model="price"></b-input>
                        </b-field>

                        <b-field label="Categorie">
                            <b-select placeholder="Selecteer een categorie" v-model="category">
                                <!-- <option value="12">Voorgerecht (MENU)</option>
                                <option value="13">Hapje (MENU)</option>
                                <option value="14">Hoofdgerecht (MENU)</option>
                                <option value="15">Dessert (MENU)</option> 
                                <option value="16">Hoofdgerecht (GOURMET MENU)</option> 
                                <option value="18">Voorgerecht (Suggestie)</option>
                                <option value="19">Hoofdgerecht (Suggestie)</option>
                                <option value="20">Desserten (Suggestie)</option> -->
                                
                                <option value="200">Voorgerecht (Feestmenu)</option>
                                <option value="204">Tweede Voorgerecht (Feestmenu)</option>
                                <option value="201">Soep (Feestmenu)</option>
                                <option value="202">Hoofdgerecht (Feestmenu)</option>
                                <option value="203">Dessert (Feestmenu)</option>
                                
                            </b-select>
                        </b-field>

                        <div class="buttons">
                            <b-button type="is-primary" v-on:click="addRecipe()" expanded>Add Recipe</b-button>
                        </div>
                    </section>
			    </div>             
			    
			</div>
		</div>
    </div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'AddOrders',
		data () {
			return {
                recipeName: "",
                price: "",
                category: ""
			}
		},
		methods: {
            addRecipe() {
                console.log(this.price)
                axios.post('https://traiteurfeest.restaurantlolivier.be/api/private/add_recipe', { // https://traiteurlolivier.restaurantlolivier.be/api/
                    token: localStorage.getItem("user-token"),
                    name: this.recipeName, 
                    price: this.price,
                    category: this.category
                    }).then(response => {
                        if (response.data == 200) {
                            this.refresh();
                        }
                    })
                    .catch(error => console.log(`${error}`))
            },

            refresh() {
                this.recipeName = "";
                this.price = "";
                this.category = "";
            }
        },
        beforeMount() {
            
        }
	}
</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        /* display: inline-block; */
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
        color: black;
	}

	.green {
		color: #42b983;
	}

    .form {
        max-width: 400px;
    }
</style>
