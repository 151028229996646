<template>
    <div class="Confirm">
		<div class="container">
            <div class=" is-primary recipes">
			    <div class="notification is-primary contact">
                    <section>
                        <h1 class="title is-1">Jouw bestelling:</h1>
                        <h5 class="title is-5">Gegevens:</h5>
                        <ul v-for="detail in details" :key="detail.name">
                            Naam: <b>{{ detail.name }}</b>
                            <br>
                            Email: <b>{{ detail.email }}</b>
                            <br>
                            Telefoon-nummer: <b>{{ detail.phone }}</b>
                            <br>
                            Betaaloptie: <b>{{ detail.payment }}</b>
                            <br>
                            Afhaal-dag: <b>{{ detail.day }}</b>
                            <br>
                            Afhaal-uur: <b>{{ detail.hour }}</b>
                            <br>
                            Opmerking: 
                            <b>{{ detail.comment }}</b>
                        </ul>
                        <hr>
                        <h5 class="title is-5">Prijs: €{{ this.price }}</h5>
                        <hr>
                        <div v-if="menu.length">
                            <h5 class="title is-5">Maart Menu:</h5>
                            <ul v-for="i in menuItems" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>

                        <div v-if="luxe.length">
                            <h5 class="title is-5">Feestmenu:</h5>
                            <ul v-for="i in luxeItems" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>

                        <div v-if="kidmenu.length">
                            <h5 class="title is-5">April menu:</h5>
                            <ul v-for="i in kidmenuItems" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>
                        
                        <div v-if="tapas.length">
                            <h5 class="title is-5">Tapas:</h5>
                            <ul v-for="i in tapas" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>

                        <div v-if="soups.length">
                            <h5 class="title is-5">Soep:</h5>
                            <ul v-for="i in soups" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>
                        
                        <div v-if="starters.length">
                            <h5 class="title is-5">Voorgerechten:</h5>
                            <ul v-for="i in starters" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>
                       
                        <div v-if="specialties.length">
                            <h5 class="title is-5">Specialiteiten:</h5>
                            <ul v-for="i in specialties" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>
                        
                        <div v-if="main.length">
                            <h5 class="title is-5">Hoofdgerechten:</h5>
                            <ul v-for="i in main" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>
                        
                        <div v-if="deserts.length">
                            <h5 class="title is-5">Dessert:</h5>
                            <ul v-for="i in deserts" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>

                        <div v-if="wine.length">
                            <h5 class="title is-5">Wijn:</h5>
                            <ul v-for="i in wine" :key="i.id">
                                <p>{{ i.name }} - <b>{{ i.amount }} keer</b></p>
                            </ul>
                            <br>
                        </div>

                        <div class="container">
                            <div class="notification is-primary contact">
                                <div class="buttons">
                                    <b-button type="is-primary" v-on:click="proceedOrder()" expanded>Bestel</b-button>
                                </div>
                            </div>
                        </div>	  
                    </section>
			    </div>
			</div>
		</div>
    </div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'Confirm',
		data () {
			return {
                details: null,
                order: null,
                menuItems: null,
                luxeItems: null,
                kidmenuItems: null,
                tapas: [],
				soups: [],
				starters: [],
				specialties: [],
				main: [],
                deserts: [],
                wine: [],
                menu: [],
                luxe: [],
                kidmenu: [],
                price: null
			}
		},
		methods: {
            addValues() {
                this.details = this.$route.params.details
                this.order = this.$route.params.orders
                this.menuItems = this.$route.params.menuItems
                this.luxeItems = this.$route.params.luxeItems
                this.kidmenuItems = this.$route.params.kidmenuItems
               // console.log(JSON.stringify(this.menuItems))
            },

            sortDishes() {
                //var temp_price = 0;
                this.order.forEach(element => {
                    //console.log(JSON.stringify(element))
                    this.price += element.price * element.amount;
                    if (element.type === 0) {
                        this.tapas.push(element);
                    } else if (element.type === 1) {
                        this.soups.push(element);
                    } else if (element.type === 2) {
                        this.starters.push(element);
                    } else if (element.type === 3) {
                        this.specialties.push(element);
                    } else if (element.type === 4) {
                        this.main.push(element);
                    } else if (element.type === 5) {
                        this.deserts.push(element);
                    } else if (element.type === 7) {
                        this.wine.push(element);
                    } else if (element.type === 99) {
                        this.menu.push(element);
                    } else if (element.type === 100) {
                        this.kidmenu.push(element);
                    } else if (element.type === 101) {
                        this.luxe.push(element);
                    }
                });

                if (this.menuItems.length != 0) {
                    this.menuItems.forEach(i => {
                        this.price += i.price * i.amount;
                    })
                }

                if (this.kidmenuItems.length != 0) {
                    this.kidmenuItems.forEach(i => {
                        this.price += i.price * i.amount;
                    })
                }

                if (this.luxeItems.length != 0) {
                    this.luxeItems.forEach(i => {
                        this.price += i.price * i.amount;
                    })
                }
                

                //this.price = temp_price;
            },

            proceedOrder() {
				//this.orderDetails = [{"name": this.name, "email": this.email, "phone": this.phone, "payment": this.payment, "day": this.date, "hour": this.hour}]
				// this.completeOrder.push(this.orderDetails)
				// this.completeOrder.push(this.selected)
				// console.log(JSON.stringify(this.orderDetails))
				// console.log(JSON.stringify(this.selected))
				// console.log(typeof(this.selected[0].price))
				axios.post('https://traiteurfeest.restaurantlolivier.be/api/proceed_confirmed', {details: this.details, items: this.order, menuItems: this.menuItems,
							luxeItems: this.luxeItems, kidmenuItems: this.kidmenuItems})
                    .catch(error => console.log(`${error}`))

				this.$router.push({name: 'End'})
					
				
			}
        },
        beforeMount() {
            this.addValues(),
            this.sortDishes()
        }
	}
</script>

<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        /* display: inline-block; */
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
        color: black;
	}

	.green {
		color: #42b983;
	}
</style>
