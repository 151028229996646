<template>
<div class="Order">
		<div class="container">
			<div class=" is-primary recipes">
				<div class="container">
  					<div class="notification is-primary contact">
						<section>
							<b-field label="Naam*">
								<b-input value="" id="name" v-model="name"></b-input>
								<div v-if="submitted && !$v.name.required" class="invalid-feedback">First Name is required</div>
							</b-field>

							<b-field label="Email*">
								<b-input type="email" value=""	maxlength="30" id="email" v-model="email"></b-input>
							</b-field>

							<b-field label="Telefoon-nummer*">
								<b-input value="" maxlength="30" id="phone" v-model="phone"></b-input>
							</b-field>

							<b-field label="Betaalmogelijkheden*">
								<b-select placeholder="Selecteer een optie" id="payment" v-model="payment">
									<option value="Bankoverschrijving">Bankoverschrijving</option>
									<option value="Cash - Gepast geld">Cash - Gepast geld</option>
									<option value="Payconic">Payconic</option>
								</b-select>
							</b-field>
							<br>

							<b-field label="Afhaal dag* (NIET MOGELIJK OP MAANDAG, DINSDAG)">
								<b-datepicker placeholder="Selecteer een datum" :unselectable-days-of-week="[1]" :unselectable-dates="disabledDates"  id="day" v-model="date"></b-datepicker>
							</b-field> 
                            <!-- :unselectable-days-of-week="[2]"  -->

							<b-field label="Afhaal uur* (Van woensdag tot en met zaterdag | Tussen 18:00 - 19:00 | Zondag: 11:00 - 12:00) | OP 24 EN 31/12: AFHALEN TUSSEN 12U EN 13U | OP 25/12 EN 01/01: AFHALEN TUSSEN 10U EN 11U">
								<b-input value="" maxlength="30" id="hour" v-model="hour"></b-input>
							</b-field>

							<br>
						</section>

  					</div>
				</div>

<section>
                    <b-collapse :open="false" class="card" animation="slide" aria-id="menulist">
                        <div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menulist">
                            <p class="card-header-title">
                                    Menu's
                            </p>
                            <a class="card-header-icon">
            	               <b-icon
                                    :icon="props.open ? 'menu-up' : 'menu-down'">
                                </b-icon>
                            </a>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <ul id="Tapas">
								
									
									<!-- <p>Hoeveel keer de {{ menuLuxe[0].name }}? - <i>€{{ menuLuxe[0].price }}</i> -->
                                    <p>Hoeveel keer de FEEST MENU 2024 - <i>€{{ menuLuxe[0].price }}</i>
									<b-numberinput class="numberinput is-green" placeholder="0" controls-position="compact" min="0" 
										controls-rounded size="is-small" :editable="false" @input="selectRecipe(menuLuxe[0].id, $event, menuLuxe[0].price, menuLuxe[0].type, menuLuxe[0].name)" />

									
									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kidstarter">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidstarter">
												<p class="card-header-title">
													Voorgerecht
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuLuxeStarter" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuLuxe(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>


									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kidstarter">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidstarter">
												<p class="card-header-title">
													Soep
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuLuxeSoep" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuLuxe(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>
									
									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kidmain">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidmain">
												<p class="card-header-title">
													Hoofdgerecht
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuLuxeMain" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuLuxe(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>

									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kiddesert">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kiddesert">
												<p class="card-header-title">
													Dessert
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuLuxeDesert" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuLuxe(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>
								
                                </ul>
                            </div>
                        </div>
                    </b-collapse>
               </section>
				

				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="tapasgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgerechten">
							<p class="card-header-title">
								Tapas - Lekkere kleine hapjes
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle tapas gerechten:</h5>
								
									
									<ul id="Tapas">
									<li v-for="i in tapas" :key="i.id">
										
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" 
											min="0" controls-rounded size="is-small" :editable="false" 
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>

				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="soepgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="soepgerechten">
							<p class="card-header-title">
								Verse soepen - 1 liter
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle soepen gerechten:</h5>
								<ul id="Tapas">
									<li v-for="i in soups" :key="i.id">
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact"
											min="0" controls-rounded size="is-small" :editable="false" 
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>

				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="voorgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="tapasgvoorgerechtenerechten">
							<p class="card-header-title">
								Voorgerechten
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle voorgerechten gerechten:</h5>
								<ul id="Tapas">
									<li v-for="i in starters" :key="i.id">
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" 
											min="0" controls-rounded size="is-small" :editable="false"
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>

				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="kroketgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kroketgerechten">
							<p class="card-header-title">
								Specialiteit van het huis : Verse kroketten (3 min. frituren)
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle kroketten gerechten:</h5>
								<ul id="Tapas">
									<li v-for="i in specialties" :key="i.id">
										
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" 
											min="0" controls-rounded size="is-small" :editable="false" 
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>

				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="hoofdgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="hoofdgerechten">
							<p class="card-header-title">
								Hoofdgerechten
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle hoofdgerechten gerechten:</h5>
								<ul id="Tapas">
									<li v-for="i in main" :key="i.id">					
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" 
											controls-rounded size="is-small" :editable="false" 
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>
				
				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="desertgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="desertgerechten">
							<p class="card-header-title">
								Desserten
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle desserten:</h5>
								<ul id="Tapas">
									<li v-for="i in deserts" :key="i.id">
										
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput is-green" placeholder="0" controls-position="compact" 
											min="0" controls-rounded size="is-small" :editable="false" 
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>

				<section>
					<b-collapse :open="false" class="card" animation="slide" aria-id="desertgerechten">
						<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="desertgerechten">
							<p class="card-header-title">
								Wijnen
							</p>
							<a class="card-header-icon">
								<b-icon
									:icon="props.open ? 'menu-up' : 'menu-down'">
								</b-icon>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<h5 class="title is-5">Alle Wijnen:</h5>
								<ul id="Tapas">
									<li v-for="i in wine" :key="i.id">
										
										<p>{{ i.name }} - <i>€{{ i.price }}</i></p>
										<b-numberinput class="numberinput is-green" placeholder="0" controls-position="compact" min="0" 
											controls-rounded size="is-small" :editable="false" 
											@input="selectRecipe(i.id, $event, i.price, i.type, i.name)" />
										
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</section>
				<br>

				<div class="container">
  					<div class="notification is-primary contact">
						<b-field label="Opmerking">
							<b-input maxlength="200" type="textarea" v-model="comment"></b-input>
						</b-field>

						<div class="container">
							<div class="notification is-primary contact">
								<div class="buttons">
									<b-button type="is-primary" v-on:click="proceedOrder()" expanded>Bestel</b-button>
								</div>
							</div>
						</div>	  
  					</div>
				</div>
			</div>
		</div>
    </div> 
</template>

<script>
	import axios from 'axios'
	import { required, email } from "vuelidate/lib/validators";
	export default {
		name: 'Order',
		data () {
			return {
				allDishes: null,
				tapas: [],
				soups: [],
				starters: [],
				specialties: [],
				main: [],
				deserts: [],
				wine: [],
				selected: [],
				menu: [],
				menuStarter: [],
                menuExtra: [],
                menuMain: [],
				menuDesert: [],
				menuLuxe: [],
				menuLuxeStarter: [],
				menuLuxeSoep: [],
				menuLuxeMain: [],
				menuLuxeDesert: [],
				kidMenu: [],
                kidMenuStarter: [],
                kidMenuMain: [],
				kidMenuDesert: [],
				selectedMainMenu: [],
				selectedChildMenu: [],
				selectedLuxeMenu: [],
				name: "",
				email: "",
				phone: "",
				payment: "", 
				date: new Date(),
				hour: "",
				orderDetails: "",
				completeOrder: [],
				comment: "",
				submitted: false,
				disabledDates: [new Date(2024, 10, 19), new Date(2024, 10, 26), new Date(2024, 11, 3), new Date(2024, 11, 10), new Date(2024, 11, 17), new Date(2024, 11, 3), new Date(2024, 11, 29), new Date(2024, 11, 30), new Date(2025, 0, 7), new Date(2025, 0, 14), new Date(2025, 0, 21), new Date(2025, 0, 28)]
				//new Date(2022, 3, 18), new Date(2022, 3, 19), new Date(2022, 3, 20), new Date(2022, 3, 21), new Date(2022, 3, 22), new Date(2022, 3, 23), new Date(2022, 3, 24), new Date(2022, 3, 25), new Date(2022, 3, 26), new Date(2022, 3, 27), new Date(2022, 3, 28)
			} 
		},
		validations: {
            name: { required },
			phone: { required },
			payment: { required },
			date: { required },
			hour: { required },
            email: { required, email }
        },
		methods: {
			sortDishes() {
				this.allDishes.forEach(element => {
					if (element.type === 0) {
						this.tapas.push(element);
					} else if (element.type === 1) {
						this.soups.push(element);
					} else if (element.type === 2) {
						this.starters.push(element);
					} else if (element.type === 3) {
						this.specialties.push(element);
					} else if (element.type === 4) {
						this.main.push(element);
					} else if (element.type === 5) {
						this.deserts.push(element);
					} else if (element.type === 7) {
						this.wine.push(element);
					} else if (element.type === 12) {
						this.menuStarter.push(element);
					} else if (element.type === 13) {
						this.menuExtra.push(element);
					} else if (element.type === 14) {
						this.menuMain.push(element);
					} else if (element.type === 15) {
						this.menuDesert.push(element);
					} else if (element.type === 18) {
						this.kidMenuStarter.push(element);
					} else if (element.type === 19) {
						this.kidMenuMain.push(element);
					} else if (element.type === 20) {
						this.kidMenuDesert.push(element);
					} else if (element.type === 99) {
						this.menu.push(element);
					} else if (element.type === 100) {
						this.kidMenu.push(element);
					} else if (element.type === 101) {
						this.menuLuxe.push(element);
					} else if (element.type === 200) {
						this.menuLuxeStarter.push(element);
					} else if (element.type === 201) {
						this.menuLuxeSoep.push(element);
					} else if (element.type === 202) {
						this.menuLuxeMain.push(element);
					} else if (element.type === 203) {
						this.menuLuxeDesert.push(element);
					}
				});
			},

			getItems() {
				axios.get('https://traiteurfeest.restaurantlolivier.be/api/get_menu') // https://traiteurfeest.restaurantlolivier.be/api/get_menu
					.then(response => (this.allDishes = response.data))
					.catch(error => console.log(`Error at getItems ${error}`))
					.finally(() => this.sortDishes());
			},

			selectRecipe(idGiven, value, price, type, name) {
				if (this.checkDuplicate(parseInt(idGiven)) && value === 0) {
					this.selected.splice(this.getIndex(parseInt(idGiven)), 1)
				} else if (this.checkDuplicate(parseInt(idGiven))) {
					this.selected[this.getIndex(parseInt(idGiven))].amount = value
				} else if (value !== 0) {
					this.selected.push({id: idGiven, amount: value, price: price, type: type, name: name})
				}

			},

			selectMenu(idGiven, value, price, type, name) {
				if (this.checkDuplicateMain(parseInt(idGiven)) && value === 0) {
					this.selectedMainMenu.splice(this.getIndexMain(parseInt(idGiven)), 1)
				} else if (this.checkDuplicateMain(parseInt(idGiven))) {
					this.selectedMainMenu[this.getIndexMain(parseInt(idGiven))].amount = value
				} else if (value !== 0) {
					this.selectedMainMenu.push({id: idGiven, amount: value, price: price, type: type, name: name})
				}

			},

			selectMenuLuxe(idGiven, value, price, type, name) {
				if (this.checkDuplicateLuxe(parseInt(idGiven)) && value === 0) {
					this.selectedLuxeMenu.splice(this.getIndexLuxe(parseInt(idGiven)), 1)
				} else if (this.checkDuplicateLuxe(parseInt(idGiven))) {
					this.selectedLuxeMenu[this.getIndexLuxe(parseInt(idGiven))].amount = value
				} else if (value !== 0) {
					this.selectedLuxeMenu.push({id: idGiven, amount: value, price: price, type: type, name: name})
				}

			},

			selectMenuKid(idGiven, value, price, type, name) {
				if (this.checkDuplicateKid(parseInt(idGiven)) && value === 0) {
					this.selectedChildMenu.splice(this.getIndexKid(parseInt(idGiven)), 1)
				} else if (this.checkDuplicateKid(parseInt(idGiven))) {
					this.selectedChildMenu[this.getIndexKid(parseInt(idGiven))].amount = value
				} else if (value !== 0) {
					this.selectedChildMenu.push({id: idGiven, amount: value, price: price, type: type, name: name})
				}

			},
			
			checkDuplicate(idGiven) {
				return this.selected.find(x => x.id === idGiven) !== undefined
			},

			checkDuplicateMain(idGiven) {
				return this.selectedMainMenu.find(x => x.id === idGiven) !== undefined
			},

			checkDuplicateLuxe(idGiven) {
				return this.selectedLuxeMenu.find(x => x.id === idGiven) !== undefined
			},

			checkDuplicateKid(idGiven) {
				return this.selectedChildMenu.find(x => x.id === idGiven) !== undefined
			},

			getIndex(id) {
				return this.selected.findIndex(p => p.id === id)
			},

			getIndexMain(id) {
				return this.selectedMainMenu.findIndex(p => p.id === id)
			},

			getIndexLuxe(id) {
				return this.selectedLuxeMenu.findIndex(p => p.id === id)
			},

			getIndexKid(id) {
				return this.selectedChildMenu.findIndex(p => p.id === id)
			},

			proceedOrder() {
				this.submitted = true;
				// console.log(+this.date === +new Date(2022, 2, 11))
				// console.log(+this.date)
				// console.log(+new Date(2022, 2, 11))
				if (+this.date === +new Date(2022, 11, 22) || +this.date === +new Date(2022, 11, 24) || +this.date === +new Date(2022, 3, 25)) {
					alert("Je kunt niet meer op deze dag bestellen.")
					return;
				}

				try {
					var amountOfMenus = this.selected.find(x => x.type === 99).amount;
					if (amountOfMenus * 3 === this.getTotalOrderedDishes(this.selectedMainMenu)) {
						console.log("allowed")
					} else {
						alert("Je hebt een gerecht te weinig of teveel gekozen in de menu.")
						return;
					}
				} catch {
					console.log("pass")
				}

				try {
					var amountOfMenusChild = this.selected.find(x => x.type === 100).amount;
					if (amountOfMenusChild * 3 === this.getTotalOrderedDishes(this.selectedChildMenu)) {
						console.log("allowed")
					} else {
						alert("Je hebt een gerecht te weinig of teveel gekozen in de Asperge menu.")
						return;
					}
				} catch {
					console.log("pass")
				}

				try {
					var amountOfLuxeMenus = this.selected.find(x => x.type === 101).amount;
					if (amountOfLuxeMenus * 4 === this.getTotalOrderedDishes(this.selectedLuxeMenu)) {
						console.log("allowed")
					} else {
						alert("Je hebt een gerecht te weinig of teveel gekozen in het Feest menu.")
						return;
					}
				} catch {
					console.log("pass")
				}

				// try {
				// 	var amountOfLuxeMenus = this.selected.find(x => x.type === 101).amount;
				// 	if (amountOfLuxeMenus != 1) {
				// 		if (amountOfLuxeMenus === this.getTotalOrderedDishes(this.selectedLuxeMenu)) {
				// 			console.log("allowed luxe")
				// 		} else {
				// 			alert("Je bent een gerecht te weinig of teveel gekozen in de luxe menu.")
				// 			return;
				// 		}
				// 	} else {
				// 		alert("De luxemenu moet minimum 2 keer genomen worden.")
				// 		return;
				// 	}
				// } catch {
				// 	console.log("pass")
				// }

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
				}

				if (this.selected.length == 0) {
					return;
				}
				
				
					var realDate = this.date.getFullYear() + "-" + ("0" + (this.date.getMonth() + 1)).slice(-2) + "-" + ("0" + this.date.getDate()).slice(-2);
					this.orderDetails = [{"name": this.name, "email": this.email, "phone": this.phone, "payment": this.payment, "day": realDate, "hour": this.hour, "comment": this.comment}]

					this.$router.push({name: 'Confirm', 
						params: {
							details: this.orderDetails,
							orders: this.selected,
							menuItems: this.selectedMainMenu,
							luxeItems: this.selectedLuxeMenu,
							kidmenuItems: this.selectedChildMenu
						}
					})
				

			},
			getTotalOrderedDishes(array) {
				var amount = 0;
				for (var i = 0; i < array.length; i++) {
					amount += array[i].amount
				}

				return amount
			}
		},
		beforeMount() {
			//console.log(this.disabledDates)
			this.getItems();
		}
	}
</script>
<style scoped>
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        margin: 0 10px;
    }
    a {
        color: #42b983;
    }

	.recipes {
		background: white;
		color: black;
	}

	.numberinput {
		max-width: 100px;
		color: #42b983;
	}

	.labeltext {
		text-align: left;
	}

	.contact {
		background: white;
	}

	.green {
		color: #42b983;
	}
</style>
<!--
									<p>Hoeveel keer de {{ menu[0].name }}? - <i>€{{ menu[0].price }}</i></p>
									<b-numberinput class="numberinput is-green" placeholder="0" controls-position="compact" min="0" 
										controls-rounded size="is-small" :editable="false" @input="selectRecipe(menu[0].id, $event, menu[0].price, menu[0].type, menu[0].name)" />

                                    <section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="menustarter">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menustarter">
												<p class="card-header-title">
													Menu voorgerecht
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuStarter" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenu(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section> 

									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="menuextra">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menuextra">
												<p class="card-header-title">
													Menu soep/voorgerecht
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuExtra" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
															<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
																@input="selectMenu(i.id, $event, i.price, i.type, i.name)" />
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>

									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="menumain">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menumain">
												<p class="card-header-title">
													Menu hoofdgerecht
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuMain" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
															<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
															@input="selectMenu(i.id, $event, i.price, i.type, i.name)"
															/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>

									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="menudesert">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="menudesert">
												<p class="card-header-title">
													Menu dessert
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in menuDesert" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
															<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
															@input="selectMenu(i.id, $event, i.price, i.type, i.name)"
															/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>
									
									<br> -->

<!--
									<br>
									
									<p>Hoeveel keer de {{ kidMenu[0].name }}? - <i>€{{ kidMenu[0].price }}</i> | <b>te bestellen tem 2 mei</b></p>
									<b-numberinput class="numberinput is-green" placeholder="0" controls-position="compact" min="0" 
										controls-rounded size="is-small" :editable="false" @input="selectRecipe(kidMenu[0].id, $event, kidMenu[0].price, kidMenu[0].type, kidMenu[0].name)" />


									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kidstarter">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidstarter">
												<p class="card-header-title">
													Voorgerecht/Soep
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in kidMenuStarter" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuKid(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>

									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kidmain">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kidmain">
												<p class="card-header-title">
													Hoofdgerecht
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in kidMenuMain" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuKid(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>

									<section>
										<b-collapse :open="false" class="card" animation="slide" aria-id="kiddesert">
											<div slot="trigger" slot-scope="props" class="card-header" role="button" aria-controls="kiddesert">
												<p class="card-header-title">
													Dessert
												</p>
												<a class="card-header-icon">
													<b-icon
														:icon="props.open ? 'menu-up' : 'menu-down'">
													</b-icon>
												</a>
											</div>
											<div class="card-content">
												<div class="content">
													<ul id="Tapas">
														<li v-for="i in kidMenuDesert" :key="i.id">		
															<p>{{ i.name }} <i v-if="i.price !== 0">- €{{ i.price }} extra</i></p>
										<b-numberinput class="numberinput" placeholder="0" controls-position="compact" min="0" controls-rounded size="is-small" :editable="false" 
										@input="selectMenuKid(i.id, $event, i.price, i.type, i.name)"
										/>
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</section>
									-->